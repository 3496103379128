<template>
  <div class="login">
    <form
      method="post"
      action="#"
      class="login-container"
      @submit.prevent="login"
    >
      <img src="../assets/images/loginlogo.svg" />
      <div class="fields">
        <input
          v-model="username"
          type="text"
          placeholder="Username / Email Address"
        />
        <br />
        <input v-model="password" type="password" placeholder="Password" />
      </div>
      <input
        class="button positive"
        type="submit"
        value="Login"
        @click.prevent="login"
      />
    </form>
    <p v-if="invalidLogin">incorrect username and/or password</p>
  </div>
</template>

<script>
import API from "../api";

export default {
  data() {
    return {
      username: "",
      password: "",
      invalidLogin: false,
    };
  },
  methods: {
    login() {
      API.login(this.username, this.password, (loggedIn, info) => {
        if (loggedIn) {
          this.$store.dispatch("loggedIn", info).then(() => {
            this.$router.push("/reports");
          });
        } else {
          this.invalidLogin = true;
        }
      });
    },
  },
};
</script>
