<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Illegal Parking Type</th>
        <td>
          {{
            $t(
              "illegal_parking." +
                report.illegal_parking_type.toLowerCase() +
                ".title"
            )
          }}
        </td>
      </tr>
      <tr>
        <th>Reason</th>
        <td v-if="report.illegal_parking_type == 'illegal'">
          {{ $t("illegal_parking.illegal." + report.illegal.toLowerCase()) }}
        </td>
        <td v-if="report.illegal_parking_type == 'unauthorised'">
          {{
            $t(
              "illegal_parking.unauthorised." +
                report.unauthorised.toLowerCase()
            )
          }}
        </td>
        <td v-if="report.illegal_parking_type == 'unpaid'">
          {{ $t("illegal_parking.unpaid." + report.unpaid.toLowerCase()) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
