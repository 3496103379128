<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Crime Type</th>
        <td>{{ $t("criminal." + report.criminal_type.toLowerCase()) }}</td>
      </tr>
      <casualties :casualties="report.casualties" />
    </table>
  </div>
</template>

<script>
import casualties from "../casualties";

export default {
  components: { casualties },
  props: ["report"],
};
</script>
