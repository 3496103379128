<template>
  <div class="report-details">
    <div class="category-details">
      <div class="category-icon">
        <img class="observation_type" :src="getImgUrl(report)" />
      </div>
      <h2 class="category-label">{{ report.type | capitalize }}</h2>
    </div>
    <table>
      <tr>
        <th>Username</th>
        <th>Date</th>
        <th>Location</th>
      </tr>
      <tr>
        <td v-if="report.reporter">{{ report.reporter.name }}</td>
        <td v-else>Unknown Reporter</td>
        <td>{{ report.timestamp | moment("dddd, MMM D, YYYY") }}</td>
        <td v-if="report.location && report.location.reverse">
          {{ report.location.reverse.city }},
          {{ report.location.reverse.country }}
        </td>
        <td v-else></td>
      </tr>
      <tr>
        <td v-if="report.reporter">
          <p v-if="report.reporter.email">{{ report.reporter.email }}</p>
          <p v-if="report.reporter.customer">{{ report.reporter.customer }}</p>
        </td>
        <td v-else></td>
        <td>{{ report.timestamp | moment("HH:mm:ss") }}</td>
        <td v-if="report.location">
          <a
            target="_blank"
            :href="
              'https://www.google.com/maps/?q=' +
              report.location.latitude +
              ',' +
              report.location.longitude
            "
            >{{ report.location.latitude }}, {{ report.location.longitude }}</a
          >
        </td>
      </tr>
      <tr class="secondary">
        <th>Report Status</th>
        <th>Submission Id</th>
        <th>Device Id</th>
      </tr>
      <tr>
        <td>
          <span v-if="report.reportStatus">{{
            $t("reportStatus." + report.reportStatus)
          }}</span>
          <span v-else>Unknown</span>
        </td>
        <td v-if="report.submissionId">
          <a target="_blank" :href="submissionUrl(report)">{{
            report.submissionId
          }}</a>
        </td>
        <td v-else>Unknown</td>
        <td>
          <span v-if="!report.reporter.deviceId">Unknown</span>
          <span v-else-if="showAllDeviceId">{{
            report.reporter.deviceId
          }}</span>
          <span v-else @click="showAllDeviceId = true"
            >{{ report.reporter.deviceId.substring(0, 5) }} ...</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "overview",
  data() {
    return {
      showAllDeviceId: false,
      home: this.$store.state.home,
    };
  },
  props: ["report"],
  methods: {
    getImgUrl(report) {
      return require(`../../assets/images/categories/${report.type}.svg`);
    },
    submissionUrl(report) {
      return `${this.home}/form/submission/${report.submissionId}`;
    },
  },
};
</script>
