import Vue from "vue";
import Paginate from "vuejs-paginate";
import { Plugin } from "vue-fragment";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import i18n from "./i18n";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(require("vue-moment"));
Vue.component("paginate", Paginate);
Vue.use(Plugin);
Vue.use(CoolLightBox);

Vue.filter("yesno", (value) => (value ? "Yes" : "No"));
Vue.filter("yesblank", (value) => (value ? "Yes" : "-"));
Vue.filter("capitalize", (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("numberornone", (value) => (value ? value : "None"));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
