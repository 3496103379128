<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Engagement Type</th>
        <td>{{ $t("engagement." + report.engagement_type.toLowerCase()) }}</td>
      </tr>
      <tr>
        <th>Person</th>
        <td>{{ report.engagement_subtypes.includes("person") | yesno }}</td>
      </tr>
      <tr>
        <th>Group</th>
        <td>{{ report.engagement_subtypes.includes("group") | yesno }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
