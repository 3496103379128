<template>
  <div class="comments">
    <h2>Comments</h2>
    <div v-if="report.comments" class="blockquote">
      <p>{{ report.comments }}</p>
    </div>
    <div v-else>
      <p>No comments submitted.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
