<template>
  <main>
    <div>
      <div class="header">
        <h1>Report Details</h1>
      </div>

      <div v-if="report" class="main-details">
        <overview :report="report"></overview>
        <component :is="report.type" :report="report" />
        <media :media="report.uploaded"></media>
        <comments :report="report"></comments>
      </div>
    </div>
  </main>
</template>

<script>
import * as components from "../components/details";
import API from "../api";

export default {
  components: {
    ...components,
  },
  mounted() {
    API.getReport(this.$route.params.id, (result) => {
      this.report = result;
    });
  },
  data() {
    return {
      report: null,
    };
  },
};
</script>
