import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";

import router from "../router";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loggedIn: Cookies.get("user.loggedin") || false,
    packageVersion: process.env.PACKAGE_VERSION || "0",
  },
  mutations: {
    loggedIn(state, { home }) {
      state.loggedIn = true;
      state.home = home;
    },
    loggedOut(state) {
      Cookies.remove("sessionid");
      state.loggedIn = false;
      router.push("/login");
    },
  },
  actions: {
    loggedIn({ commit }, info) {
      commit("loggedIn", info);
    },
    loggedOut({ commit }) {
      commit("loggedOut");
    },
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    isAuthenticated: (state) => {
      return state.loggedIn;
    },
  },
});

export default store;
