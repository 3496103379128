import axios from "axios";

let API = require("./real.js");
if (process.env.NODE_ENV === "development") {
  API = require("./dummy.js");
}

function reverseLocation(location, done) {
  axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}`
    )
    .then((resp) => {
      done(resp.data.address);
    });
}

export default { ...API, reverseLocation };
