<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Checkpoint Type</th>
        <td>{{ $t("checkpoint." + report.checkpoint_type.toLowerCase()) }}</td>
      </tr>
      <tr>
        <th>Armed</th>
        <td>{{ report.checkpoint_subtypes.includes("armed") | yesblank }}</td>
      </tr>
      <tr>
        <th>Blocked</th>
        <td>{{ report.checkpoint_subtypes.includes("blocked") | yesblank }}</td>
      </tr>
      <tr>
        <th>Technical</th>
        <td>
          {{
            report.checkpoint_subtypes.includes("technical_present") | yesblank
          }}
        </td>
      </tr>
      <tr>
        <th>Personnel</th>
        <td>{{ report.personnel }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
