<template>
  <div id="app">
    <navigation v-if="isLoggedIn" />
    <router-view />
  </div>
</template>

<style lang="scss">
@use "@/assets/styles/style.scss";
</style>

<script>
import navigation from "./components/navigation";

export default {
  components: { navigation },
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>
