<template>
  <main>
    <div>
      <div class="header">
        <h1>Admin</h1>
      </div>
      <div class="main-details">
        <ul>
          <li><a @click="delete_all_reports">Delete All Reports</a></li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import API from "../api";

export default {
  methods: {
    delete_all_reports() {
      API.deleteAllReports(() => {
        alert("All reports deleted");
      });
    },
  },
};
</script>
