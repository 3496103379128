<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>UAS Type</th>
        <td>{{ $t("uas." + report.uas_type.toLowerCase()) }}</td>
      </tr>
      <tr>
        <th>Size</th>
        <td>{{ $t("size." + report.size.toLowerCase()) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
