<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Threat Type</th>
        <td>{{ $t("threat." + report.threat_type.toLowerCase()) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
