<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Conflict Type</th>
        <td>{{ $t("conflict." + report.conflict_type.toLowerCase()) }}</td>
      </tr>
      <tr>
        <th>Details</th>
        <td>
          {{
            report.conflict_subtypes
              .map((w) => {
                return $t("conflict.subtypes." + w.toLowerCase());
              })
              .join(", ")
          }}
        </td>
      </tr>
      <casualties :casualties="report.casualties" />
    </table>
  </div>
</template>

<script>
import casualties from "../casualties";

export default {
  props: ["report"],
  components: { casualties },
};
</script>
