<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Cache Type</th>
        <td>
          {{
            report.cache_types
              .map((w) => {
                return $t("cache." + w.toLowerCase());
              })
              .join(", ")
          }}
        </td>
      </tr>
      <tr>
        <th>Cleared?</th>
        <td v-if="report.cache_cleared.includes('cleared')">Yes</td>
        <td v-else-if="report.cache_cleared.includes('not_cleared')">No</td>
        <td v-else>-</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
