<template>
  <nav>
    <div class="nav-container">
      <a
        v-if="isReportDetails"
        href=""
        class="back"
        v-on:click.prevent="$router.go(-1)"
      >
        <img src="../assets/images/icons/backarrow.svg" />
        <p>Back to reports</p>
      </a>
      <a href="" class="logo">
        <img src="../assets/images/logo.svg" />
        <p>SMART <span>admin@company.com</span></p>
      </a>
      <ul>
        <li class="reports">
          <router-link to="/reports">Reports</router-link>
        </li>
        <li>
          <a href="/logs">Logs</a>
        </li>
        <li>
          <router-link to="/admin">Admin</router-link>
        </li>
      </ul>
      <a href="" class="logout">
        <img src="../assets/images/icons/logout.svg" />
        <router-link to="/logout">Logout</router-link>
      </a>
      <p class="version">Version {{ $store.getters.appVersion }}</p>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navigation",
  computed: {
    isReportDetails: function () {
      return this.$route.name === "reportDetails";
    },
  },
};
</script>
