<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Crash Type</th>
        <td>
          {{
            report.crash_types
              .map((w) => {
                return $t("hazard.crash." + w.toLowerCase());
              })
              .join(", ")
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
