<template>
  <fragment v-if="casualties">
    <tr>
      <th>Casualties</th>
      <td>{{ casualties | total | numberornone }}</td>
    </tr>
    <tr>
      <th>KIA <span class="friendly">Friendly</span></th>
      <td>{{ casualties.kia.friendly | numberornone }}</td>
    </tr>
    <tr>
      <th>WIA <span class="friendly">Friendly</span></th>
      <td>{{ casualties.wia.friendly | numberornone }}</td>
    </tr>
    <tr>
      <th>KIA <span class="neutral">Neutral</span></th>
      <td>{{ casualties.kia.neutral | numberornone }}</td>
    </tr>
    <tr>
      <th>WIA <span class="neutral">Neutral</span></th>
      <td>{{ casualties.wia.neutral | numberornone }}</td>
    </tr>
    <tr>
      <th>KIA <span class="enemy">Enemy</span></th>
      <td>{{ casualties.kia.enemy | numberornone }}</td>
    </tr>
    <tr>
      <th>WIA <span class="enemy">Enemy</span></th>
      <td>{{ casualties.wia.enemy | numberornone }}</td>
    </tr>
  </fragment>
</template>

<script>
export default {
  name: "casualties",
  props: ["casualties"],
  filters: {
    total: function (casualties) {
      return (
        casualties.kia.enemy +
        casualties.kia.neutral +
        casualties.kia.friendly +
        casualties.wia.enemy +
        casualties.wia.neutral +
        casualties.wia.friendly
      );
    },
  },
};
</script>
