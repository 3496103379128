<template>
  <div class="images">
    <h2>Images/Videos</h2>

    <CoolLightBox
      :items="media"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    >
    </CoolLightBox>

    <div v-if="media && media.length > 0" class="image-gallery">
      <div
        class="report-image"
        v-for="(mediaLink, imageIndex) in media"
        v-bind:key="imageIndex"
      >
        <a @click.prevent="index = imageIndex" :href="mediaLink"
          ><img :src="mediaLink"
        /></a>
      </div>
    </div>
    <div v-else>
      <p>No media uploaded.</p>
    </div>
  </div>
</template>

<style>
div.mediaList img {
  float: left;
  padding: 1em;
  width: 100px;
  height: 100px;
}
div.mediaList::after {
  content: "";
  clear: both;
  display: table;
}
</style>

<script>
export default {
  name: "media",
  props: ["media"],
  data: function () {
    return {
      index: null,
    };
  },
};
</script>
