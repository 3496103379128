<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Ied Type</th>
        <td v-if="report.ied_type">
          {{ $t("ied." + report.ied_type.toLowerCase()) }}
        </td>
        <td v-else>-</td>
      </tr>
      <tr>
        <th>Status</th>
        <td v-if="report.status">
          {{ $t("ied." + report.status.toLowerCase()) }}
        </td>
        <td v-else>-</td>
      </tr>
      <casualties :casualties="report.casualties" />
    </table>
  </div>
</template>

<script>
import casualties from "../casualties";

export default {
  components: { casualties },
  props: ["report"],
};
</script>
