<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Observation Type</th>
        <td>
          {{ $t("observation." + report.observation_type.toLowerCase()) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
