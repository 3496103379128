<template>
  <main>
    <div class="header">
      <h1>Reports</h1>
      <div class="refresh" @click="fetchPage(page)">
        <img src="../assets/images/icons/refresh.svg" />
      </div>
      <div v-if="items.length > 0 || keywords" class="search">
        <div class="search-icon"></div>
        <input
          type="search"
          class="left"
          placeholder="Search..."
          v-model="keywords"
          v-on:keyup.enter="fetchPage(1)"
        />
      </div>
    </div>

    <div v-if="items.length > 0" class="main-reports">
      <table class="reports-table">
        <tr>
          <th></th>
          <th>Category</th>
          <th>Reporter</th>
          <th>Date</th>
          <th>Location</th>
        </tr>
        <tr
          :class="report.type"
          v-for="report in items"
          :key="report._id"
          @click="clicked(report)"
        >
          <td>
            <img :src="getImgUrl(report)" />
          </td>
          <td>{{ report.type }}</td>
          <td v-if="report.reporter">
            {{ report.reporter.name }}
            <span class="sub-info" v-if="report.reporter.email">{{
              report.reporter.email
            }}</span>
            <span class="sub-info" v-if="report.reporter.customer">{{
              report.reporter.customer
            }}</span>
          </td>
          <td v-else>Unknown</td>
          <td>
            {{ report.timestamp | moment("MMM D, YYYY") }}
            <span class="sub-info">{{
              report.timestamp | moment("HH:mm:ss")
            }}</span>
          </td>
          <td>
            <template v-if="report.location">
              <span v-if="report.location.reverse">
                {{ report.location.reverse.city }},
                {{ report.location.reverse.country }}
              </span>
              <span v-else></span>
              <span class="sub-info" v-if="report.location">
                <a
                  target="_blank"
                  :href="
                    'https://www.google.com/maps/?q=' +
                    report.location.latitude +
                    ',' +
                    report.location.longitude
                  "
                  >{{ report.location.latitude }},
                  {{ report.location.longitude }}</a
                >
              </span>
            </template>
            <span v-else>Unknown Location</span>
          </td>
        </tr>
      </table>

      <paginate
        v-model="page"
        :page-count="pages"
        :page-range="3"
        :click-handler="fetchPage"
        :margin-pages="2"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
    <div v-else-if="keywords">
      <table class="reports-table">
        <tr>
          <td>No reports found</td>
        </tr>
      </table>
    </div>
    <div v-else-if="noReports" class="main-reports-empty">
      <h1>No Submitted Reports</h1>
      <p class="bold">
        Currently no reports have been submitted via the SaltReport Application.
      </p>
      <img src="../assets/images/reports3.svg" />
      <p class="body-text">
        Once reports have been submitted they will be listed on this screen like
        the example above. Reporters will be able to view the details associated
        with each report by clicking on the report. This will direct you to
        another page displaying a breakdown of the submitted information.
      </p>
    </div>
  </main>
</template>

<script>
import API from "../api";

export default {
  methods: {
    clicked(report) {
      this.$router
        .push({ name: "reportDetails", params: { id: report._id } })
        .catch(() => {});
    },
    getImgUrl(report) {
      return require(`../assets/images/categories/${report.type}.svg`);
    },
    fetchPage(page) {
      window.console.log(`Fetching page ${page}`);
      this.page = page;
      API.getReports(
        this.limit,
        this.page,
        this.keywords,
        (results, numberOfPages) => {
          this.items = results;
          this.pages = numberOfPages;
          this.noReports = false;

          var routerParams = { page: this.page, limit: this.limit };
          if (this.keywords) {
            routerParams.keywords = this.keywords;
          } else if (this.items.length === 0) {
            this.noReports = true;
          }
          this.$router.push({ query: routerParams }).catch(() => {});
        }
      );
    },
  },
  data() {
    return {
      noReports: false,
      items: [],
      limit: this.$route.query.limit || 5,
      page: Math.max(this.$route.query.page, 1) || 1,
      pages: 0,
      keywords: this.$route.query.keywords || "",
    };
  },
  mounted() {
    this.fetchPage(this.page);
  },
};
</script>
