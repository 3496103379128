import Vue from "vue";
import VueRouter from "vue-router";
import Reports from "../views/Reports.vue";
import ReportDetails from "../views/ReportDetails.vue";
import Login from "../views/Login";
import Admin from "../views/Admin";

import API from "../api";
import store from "../store";

const ifNotAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    document.body.className = "";
    next("/reports");
  } else {
    document.body.className = "login";
    next();
  }
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    document.body.className = "";
    next();
  } else {
    document.body.className = "login";
    next("/login");
  }
};

const logout = (to, from, next) => {
  API.logout(() => {
    store.dispatch("loggedOut").then(() => {
      next("/login");
    });
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/reports",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/reports/:id",
    name: "reportDetails",
    component: ReportDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/logout",
    beforeEnter: logout,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
