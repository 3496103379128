<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Surveillance Type</th>
        <td>
          {{ $t("surveillance." + report.surveillance_type.toLowerCase()) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
