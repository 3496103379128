<template>
  <div class="further-details">
    <h2>Further Details</h2>
    <table>
      <tr>
        <th>Hazard Type</th>
        <td>{{ $t("hazard.type." + report.hazard_type.toLowerCase()) }}</td>
      </tr>
      <template v-if="report.hazard_type == 'weather'">
        <tr>
          <th>Weather</th>
          <td>
            {{
              report.hazard_subtypes
                .map((w) => {
                  return $t("hazard.weather." + w.toLowerCase());
                })
                .join(", ")
            }}
          </td>
        </tr>
      </template>
      <template v-else-if="report.hazard_type == 'crash'">
        <tr>
          <th>Crash</th>
          <td>
            {{
              report.hazard_subtypes
                .map((w) => {
                  return $t("hazard.crash." + w.toLowerCase());
                })
                .join(", ")
            }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  props: ["report"],
};
</script>
